<template>
    <div class="flex flex--100 restaurant-order" :class="getModifiers()">
        <div class="flex flex--100 flex--x-align-end cashier-control-box" v-show="showCashierControls">
            <simple-button :text="$t('generic.modifyOrder').toUpperCase()" @click="modify" />
            <simple-button v-if="Number.isFinite(order.paymentMethod)" :text="$t('generic.reprintFiscalReceipt').toUpperCase()" @click="reprintFiscalReceipt" />
            <simple-button :text="$t('generic.printIntern').toUpperCase()" @click="printReminder" />
        </div>
        <div class="flex flex--100 flex--direction-column restaurant-order__box">
            <div class="flex flex--100 flex--y-align-center">
                <div class="flex flex--50">
                    <h1 class="flex flex--y-align-center title">{{ $t("order.order") }} #{{ order.dailyId }} {{ order.sceneId ? `- ${restaurantProcessor.getSceneLocalizedName(order.sceneId)} #${ order.sceneDailyId }` : "" }}</h1>
                </div>
                <div class="flex flex--50 flex--x-align-end order-status"
                    :class="getOrderStatusModifiers(order.status)">
                    <span class="label">{{ $t(`order.statusMap.${order.status}`).toUpperCase() }}</span>
                </div>
            </div>
            <div class="flex flex--100 restaurant-name">
                <hr class="separator" />
                <div class="flex flex--100">
                    <div class="flex flex--50">
                        <span class="restaurant-order__key">{{ $t("order.restaurant") }}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <span class="restaurant-order__value">{{ restaurant.name }}, {{ restaurant.address }}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 table" v-if="order.tables">
                <hr class="separator" />
                <div class="flex flex--100">
                    <div class="flex flex--50">
                        <span class="restaurant-order__key">{{ $t("order.table") }}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <span class="restaurant-order__value">{{ order.tables[0].name }}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 date">
                <hr class="separator" />
                <div class="flex flex--100">
                    <div class="flex flex--50">
                        <span class="restaurant-order__key">{{ $t("generic.date") }}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <span class="restaurant-order__value">{{ date.toLocaleString($i18n.locale) }}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 expand-arrow" v-show="compactView">
                <hr class="separator" />
                <div class="flex flex--100 flex--x-align-center">
                    <span class="flex arrow-button" @click="toggleExpansion">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" xml:space="preserve"
                            class="flex arrow-button__icon">
                            <path
                                d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                        </svg>
                    </span>
                </div>
            </div>
            <div class="flex flex--100 restaurant-order__expanded" :ref="el => expandableRef = el">
                <div class="flex flex--100 unique-id">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.uniqueCode") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">{{ order.id }}</span>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 origin" >
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.origin") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">{{ $t(`order.originMap.${order.origin}`) }}</span>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 payment-method">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.paymentMethod") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">{{ Number.isFinite(order.paymentMethod) ?
                                $t(`order.paymentMethodMap.${order.paymentMethod}`) : 'N/D' }}</span>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 payment-date">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.paymentDate") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">
                                {{ paymentDateString.toLocaleString($i18n.locale) || $t("generic.notApplicable") }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 delivery-location"  v-if="!order.tables && order.deliveryLocation === OrderDeliveryLocation.RESTAURANT || order.deliveryLocation !== OrderDeliveryLocation.RESTAURANT">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.deliveryLocation") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">{{
                                $t(`order.deliveryLocationMap.${order.deliveryLocation}`) }}</span>
                        </div>
                    </div>
                </div>
                <template v-if="order.receiver && order.receiver.address">
                    <div class="flex flex--100 delivery-address">
                        <hr class="separator" />
                        <div class="flex flex--100">
                            <div class="flex flex--50">
                                <span class="restaurant-order__key">{{ $t("order.receiver.address") }}</span>
                            </div>
                            <div class="flex flex--50 flex--x-align-end">
                                <span class="restaurant-order__value">{{ order.receiver.address }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="order.receiver && order.receiver.fullName">
                    <div class="flex flex--100 delivery-address">
                        <hr class="separator" />
                        <div class="flex flex--100">
                            <div class="flex flex--50">
                                <span class="restaurant-order__key">{{ $t("order.receiver.name") }}</span>
                            </div>
                            <div class="flex flex--50 flex--x-align-end">
                                <span class="restaurant-order__value">{{ order.receiver.fullName }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="order.receiver && order.receiver.mobilePhone">
                    <div class="flex flex--100 delivery-address">
                        <hr class="separator" />
                        <div class="flex flex--100">
                            <div class="flex flex--50">
                                <span class="restaurant-order__key">{{ $t("order.receiver.mobilePhone") }}</span>
                            </div>
                            <div class="flex flex--50 flex--x-align-end">
                                <span class="restaurant-order__value">{{ order.receiver.mobilePhone }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="flex flex--100 delivery-date">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.deliveryDate") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">{{ deliveryDateString }}</span>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 product-list">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--100">
                            <span class="restaurant-order__key">{{ $t("order.products") }}</span>
                        </div>
                        <div class="flex flex--100">
                            <ul class="flex flex--100 item-list">
                                <li class="flex flex--100 item-list__item" v-for="(orderProduct, i) in order.products"
                                    :key="orderProduct.id">
                                    <!-- <fixed-menu-list> -->
                                    <template v-if="restaurantProcessor.productIsFixedMenu(orderProduct.id)">
                                        <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus"
                                            :key="menu.id">
                                            <div class="flex flex--100 flex--y-align-center">
                                                <div class="flex flex--70">
                                                    <span class="product-name">
                                                        x1 {{
                                                        restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                        }}

                                                        {{ showPrice ? `(€
                                                        ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                        + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                        i))})` : '' }}
                                                    </span>
                                                </div>
                                                <div class="flex flex--30 flex--x-align-end">
                                                    <span class="product-status"
                                                        :class="getOrderProductStatusModifiers(orderProduct)">
                                                        <template v-if="order.status === OrderStatus.TO_PAY">
                                                            {{ $t("order.statusMap.0").toUpperCase() }}
                                                        </template>
                                                        <template
                                                            v-else-if="orderProduct.deliveredVolume === orderProduct.requestedVolume">
                                                            {{ $t("order.statusMap.2").toUpperCase() }}
                                                        </template>
                                                        <template
                                                            v-else-if="orderProduct.completedVolume === orderProduct.requestedVolume">
                                                            {{ $t("order.statusMap.3").toUpperCase() }}
                                                        </template>
                                                        <template v-else>
                                                            {{ $t("order.statusMap.1").toUpperCase() }}
                                                        </template>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                <div class="flex flex--100 fixed-menu-product"
                                                    v-for="product in menu.products" :key="product.id">
                                                    <div class="flex flex--100 flex--y-align-center">
                                                        <div class="flex flex--70">
                                                            <span class="fixed-menu-product">
                                                                x{{ product.requestedVolume }} {{
                                                                restaurantProcessor.getProductLocalizedName(product.id)
                                                                }}
                                                            </span>
                                                        </div>
                                                        <div class="flex flex--30 flex--x-align-end">
                                                            <span class="product-status"
                                                                :class="getOrderProductStatusModifiers(product)">
                                                                <template v-if="order.status === OrderStatus.TO_PAY">
                                                                    {{ $t("order.statusMap.0").toUpperCase() }}
                                                                </template>
                                                                <template
                                                                    v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                    {{ $t("order.statusMap.2").toUpperCase() }}
                                                                </template>
                                                                <template
                                                                    v-else-if="product.completedVolume === product.requestedVolume">
                                                                    {{ $t("order.statusMap.3").toUpperCase() }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ $t("order.statusMap.1").toUpperCase() }}
                                                                </template>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <fixed-menu-list> -->
                                    <!-- <custom-product-list> -->
                                    <template v-else-if="restaurantProcessor.productIsCustomProduct(orderProduct.id)">
                                        <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus" :key="menu.id">
                                            <div class="flex flex--70">
                                                <span class="product-name">
                                                    x1 {{ restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                    (€ {{ normalizePriceToDisplay(getCustomProductMenuPrice(menu)) }})
                                                </span>
                                            </div>
                                            <div class="flex flex--30 flex--x-align-end">
                                                <span class="product-status" :class="getOrderProductStatusModifiers(orderProduct)">
                                                    <template v-if="order.status === OrderStatus.TO_PAY">
                                                        {{ $t("order.statusMap.0").toUpperCase() }}
                                                    </template>
                                                    <template v-else-if="orderProduct.deliveredVolume === orderProduct.requestedVolume">
                                                        {{ $t("order.statusMap.2").toUpperCase() }}
                                                    </template>
                                                    <template v-else-if="orderProduct.completedVolume === orderProduct.requestedVolume">
                                                        {{ $t("order.statusMap.3").toUpperCase() }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $t("order.statusMap.1").toUpperCase() }}
                                                    </template>
                                                </span>
                                            </div>
                                            <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                <div
                                                    class="flex flex--100 fixed-menu-product"
                                                    v-for="product in menu.products"
                                                    :key="product.id"
                                                >
                                                    <div class="flex flex--70">
                                                        <span class="fixed-menu-product">
                                                            x{{ product.requestedVolume }} {{ restaurantProcessor.getProductLocalizedName(product.id) }}
                                                            (€ {{ normalizePriceToDisplay(restaurantProcessor.getProductById(product.id).price) }})
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status" :class="getOrderProductStatusModifiers(product)">
                                                            <template v-if="order.status === OrderStatus.TO_PAY">
                                                                {{ $t("order.statusMap.0").toUpperCase() }}
                                                            </template>
                                                            <template v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                {{ $t("order.statusMap.2").toUpperCase() }}
                                                            </template>
                                                            <template v-else-if="product.completedVolume === product.requestedVolume">
                                                                {{ $t("order.statusMap.3").toUpperCase() }}
                                                            </template>
                                                            <template v-else>
                                                                {{ $t("order.statusMap.1").toUpperCase() }}
                                                            </template>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <custom-product-list> -->
                                    <!-- <base-product-list> -->
                                    <template v-else>
                                        <div class="flex flex--100 flex--y-align-center">
                                            <div class="flex flex--70">
                                                <span class="product-name"
                                                    v-show="getProductsNoVariations(orderProduct) > 0"
                                                >
                                                    x{{ getProductsNoVariations(orderProduct) }} {{
                                                    restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                    <template v-if="showPrice"> 
                                                        <span class="product-price">
                                                            {{  `(€ ${ getNoVariationProductTotalPrice(orderProduct) }` }}
                                                        </span>
                                                        <span class="product-price product-price__discounted" v-if="getNoVariationProductTotalPrice(orderProduct) !== getNoVariationProductBeforeDiscount(orderProduct)">
                                                            {{  `€ ${ getNoVariationProductBeforeDiscount(orderProduct) })` }}
                                                        </span>
                                                        <span class="product-price" v-else>)</span>
                                                         <template v-if="onePlusOne(orderProduct)">
                                                        <div class="product-tag product-tag__onePlusOne">
                                                            <span>{{ $t("discountStrategy.onePlusOne" )}}</span>
                                                        </div>
                                                        </template>
                                                        <template v-if="fixedDiscount(orderProduct)">
                                                            <div class="product-tag product-tag__discount">
                                                                -{{ fixedDiscount(orderProduct) }}€
                                                            </div>
                                                        </template>
                                                        <template v-if="percentageDiscount(orderProduct)">
                                                            <div class="product-tag product-tag__discount">
                                                                -{{ percentageDiscount(orderProduct) }}%
                                                            </div>
                                                        </template>
                                                    </template>
                                                </span>
                                                <template v-for="(item, i) in orderProduct.requestedVolume">
                                                    <div class="product-name" :key="i"
                                                        v-if="orderProductVolumeIndexHasVariations(orderProduct, i)">
                                                        <span class="product-name">
                                                            x1 {{
                                                            restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                            }}
                                                            {{ getOrderProductVariationsTextByVolumeIndex(orderProduct,
                                                            i)
                                                            }}
                                                            <span class="product-price">
                                                            {{  `(€ ${ getVariationProductSinglePrice(orderProduct, i) }` }}
                                                            </span>
                                                            <span class="product-price product-price__discounted" v-if="getVariationProductSinglePrice(orderProduct, i) !== getVariationProductSinglePriceBeforeDiscount(orderProduct, i)">
                                                                {{  `€ ${ getVariationProductSinglePriceBeforeDiscount(orderProduct, i) })` }}
                                                            </span>
                                                            <span class="product-price" v-else>)</span>
                                                        </span>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="flex flex--30 flex--x-align-end">
                                                <span class="product-status"
                                                    :class="getOrderProductStatusModifiers(orderProduct)">
                                                    <template v-if="order.status === OrderStatus.TO_PAY">
                                                        {{ $t("order.statusMap.0").toUpperCase() }}
                                                    </template>
                                                    <template
                                                        v-else-if="orderProduct.deliveredVolume === orderProduct.requestedVolume">
                                                        {{ $t("order.statusMap.2").toUpperCase() }}
                                                    </template>
                                                    <template
                                                        v-else-if="orderProduct.completedVolume === orderProduct.requestedVolume">
                                                        {{ $t("order.statusMap.3").toUpperCase() }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $t("order.statusMap.1").toUpperCase() }}
                                                    </template>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- </base-product-list> -->
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <template v-if="showPrice && order.additions.length > 0">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.additions") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">€ {{
                                normalizePriceToDisplay(orderAdditionsTotal(order)) }}</span>
                        </div>
                        <ul class="flex flex--100 item-list">
                            <li class="flex flex--100 item-list__item" v-for="(additions) in order.additions"
                                :key="additions.name">
                                <div class="flex flex--100 flex--y-align-center">
                                    <div class="flex flex--70">
                                        <span class="product-name">
                                            {{ additions.description }}
                                        </span>
                                    </div>
                                    <div class="flex flex--30 flex--x-align-end">
                                        <span class="restaurant-order__value">
                                            € {{  normalizePriceToDisplay(additions.value) }}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </template>
                <template v-if="showPrice && order.deductions.length > 0">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.deductions") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">€ {{
                                normalizePriceToDisplay(orderDeductionsTotal(order)) }}</span>
                        </div>
                        <ul class="flex flex--100 item-list">
                            <li class="flex flex--100 item-list__item" v-for="(deduction) in order.deductions"
                                :key="deduction.name">
                                <div class="flex flex--100 flex--y-align-center">
                                    <div class="flex flex--70">
                                        <span class="product-name">
                                            {{ deduction.description }}
                                        </span>
                                    </div>
                                    <div class="flex flex--30 flex--x-align-end">
                                        <span class="restaurant-order__value">
                                            € {{  normalizePriceToDisplay(deduction.value) }}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </template>
                <template v-if="showPrice && orderSubtotal(order) !== orderTotal(order)">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.subtotal") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">€ {{ normalizePriceToDisplay(orderSubtotal(order))
                                }}</span>
                        </div>
                    </div>
                </template>
                <template v-if="showPrice && orderDiscount(order) > 0">
                    <hr class="separator" />
                    <div class="flex flex--100">
                        <div class="flex flex--50">
                            <span class="restaurant-order__key">{{ $t("order.discount") }}</span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="restaurant-order__value">{{ orderDiscountPercentage(order) }}% &bull; € {{
                                normalizePriceToDisplay(orderDiscount(order)) }}</span>
                        </div>
                    </div>
                </template>
                <hr class="separator" v-if="showPrice" />
                <div class=" flex flex--100" v-if="showPrice">
                    <div class="flex flex--50">
                        <span class="restaurant-order__key">{{ $t("order.total") }}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <span class="restaurant-order__value"
                            :class="order.totalPrice !== undefined ? 'restaurant-order__line-through' : ''">€ {{
                            normalizePriceToDisplay(orderTotal(order)) }}</span>
                        <span v-if="order.totalPrice !== undefined" class="restaurant-order__value">€ {{
                            normalizePriceToDisplay(order.totalPrice) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleButton from "@/components/inputs/SimpleButton";
import { isDesktop } from "@/main";
import { OrderStatus, OrderDeliveryLocation, } from "@/orders/RestaurantOrder";
import { RestaurantProcessor, } from "@/utilities";
import { cloneDeep } from "lodash";
import { DiscountStrategies } from "@/products/DiscountStrategies";

export default {
    name: "RestaurantOrder",
    components: {
        SimpleButton,
    },
    props: {
        restaurantPresentation: {
            type: Object,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
        compactView: {
            type: Boolean,
            default: true,
        },
        showCashierControls: {
            type: Boolean,
            default: false,
        },
        showPrice: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            OrderStatus,
            OrderDeliveryLocation,
            isExpanded: false,
        };
    },
    methods: {
        getModifiers () {
            return {
                "restaurant-order--expanded": this.isExpanded,
                "restaurant-order--compact-view": this.compactView,
            };
        },

        getOrderStatusModifiers (status) {
            return {
                "order-status--in-preparation": status === OrderStatus.IN_PREPARATION,
                "order-status--in-delivery": status === OrderStatus.IN_DELIVERY,
                "order-status--delivered": status === OrderStatus.DELIVERED,
            };
        },

        getOrderProductStatusModifiers (orderProduct) {
            if (this.order.status === OrderStatus.TO_PAY) {
                return {};
            }

            if (orderProduct.requestedVolume === orderProduct.deliveredVolume) {
                return {
                    "product-status--delivered": true,
                };
            }
            else if (orderProduct.requestedVolume === orderProduct.completedVolume) {
                return {
                    "product-status--in-delivery": true,
                };
            }
            else {
                return {
                    "product-status--in-preparation": true,
                };
            }
        },

        expand () {
            this.isExpanded = true;
        },

        compact () {
            this.isExpanded = false;
        },

        toggleExpansion () {
            this.isExpanded = !this.isExpanded;
        },

        orderDiscountPercentage (order) {
            return Math.min(order.discounts.reduce((percentage, discount) => percentage + discount.percentage, 0), 100);
        },

        orderSubtotal (order) {
            return order.products.reduce(
                (subtotal, orderProduct) =>
                    (this.restaurantProcessor.productIsCustomProduct(orderProduct.id) ? 1 : orderProduct.requestedVolume)
                    * (this.restaurantProcessor.productIsCustomProduct(orderProduct.id) ? this.getCustomProductPrice(orderProduct) : orderProduct.unitPrice)
                    + this.getProductVariationsTotalPrice(orderProduct) + this.getDiscountedProductPrice(orderProduct)
                    + subtotal, 0
            ) + this.orderAdditionsTotal(order) + this.orderDeductionsTotal(order);
        },

        getCustomProductPrice (product) {
            let total = 0;
            product.requestedMenus.forEach((menu) => {
                total += this.getCustomProductMenuPrice(menu);
            });

            return total;
        },

        getDiscountedProductPrice (orderProduct) {
            if (!orderProduct.discountStrategies) {
                return 0;
            }
            const volume = Number.parseInt(orderProduct.requestedVolume);
            let totalPrice = 0;

            if (orderProduct.discountStrategies.includes(DiscountStrategies.onePlusOne.id)) {
                totalPrice -= DiscountStrategies.onePlusOne.applyDiscount(
                    volume,
                    orderProduct.unitPrice,
                );
            }
                
            const fixedDiscount = orderProduct.discountStrategies.find((s) =>
                String(s).startsWith("-")
            );
                const fixed = Boolean(fixedDiscount);
            if (fixed) {
                const fixedValue = fixedDiscount.substring(1);
                totalPrice -= DiscountStrategies.fixed.applyDiscount(
                    volume,
                    orderProduct.unitPrice,
                    fixedValue
                );
            }

            const percentageDiscount = orderProduct.discountStrategies.find((s) =>
            String(s).startsWith("%")
            );
            const percentage = Boolean(percentageDiscount);
            if (percentage) {
                const percentageValue = percentageDiscount.substring(1);
                totalPrice -= DiscountStrategies.percentage.applyDiscount(
                    volume,
                    orderProduct.unitPrice,
                    percentageValue
                );
            }

            return totalPrice;
        },

        getCustomProductMenuPrice (menu) {
            let total = 0;

            menu.products.forEach((customProductProduct) => {
                total += this.restaurantProcessor.getProductById(customProductProduct.id).price;
            });

            return total;
        },

        orderDiscount (order) {
            const discountPercentage = this.orderDiscountPercentage(order);

            if (discountPercentage === 0) {
                return 0;
            }

            return discountPercentage * this.orderSubtotal(order) / 100;
        },

        orderVariationsTotalPrice () {

        },

        orderAdditionsTotal (order) {
            return order.additions.reduce((total, addition) => total + addition.value, 0);
        },

        orderDeductionsTotal (order) {
            return order.deductions.reduce((total, deduction) => total + deduction.value, 0);
        },

        orderDiscountTotal (order) {
            return order.products.reduce((totalPrice, orderProduct) => {
                const volume = Number.parseInt(orderProduct.requestedVolume);

                totalPrice += this.getDiscountedPrice(orderProduct, volume)

                return totalPrice;
            }, 0);
        },

        orderTotal (order) {
            return Math.max(0, this.orderSubtotal(order) - this.orderDiscount(order));
        },

        printReminder () {
            if (isDesktop) {
                try {
                    const { ipcRenderer, } = window.require("electron");
                    const order = cloneDeep(this.order);

                    /**
                     * Link price and localizations to order products to keep compatibility with FortuneRMS Desktop (avoids breaking printers).
                     */
                    order.products.forEach((orderProduct) => {
                        const product = this.restaurantProcessor.getProductById(orderProduct.id);
                        // I decided to keep product price, this is an internal receipt, the issue will happens only if someone changes discount strategy during open time.
                        orderProduct.price = orderProduct.unitPrice; // Question: If I have a product discounted while place order, then the discount expired when I reprint, the price should be which one?
                        orderProduct.localizations = product.localizations;
                        orderProduct.variationsDetails = this.restaurantProcessor.getProductVariations(orderProduct.id);
                        if (!product.discountExpirationTimestamp || product.discountExpirationTimestamp > Date.now()) {
                            orderProduct.discountStrategies = product.discountStrategies;
                        }
                        this.bindFixedMenuProductLocalizations(order); // Link fixed menu products localizations for receipt
                        this.bindCustomProductProductLocalizations(order); // Link custom dish products localizations for receipt
                    });

                    ipcRenderer.send("printOrder", JSON.stringify(order));
                }
                catch {
                    // Silence is golden.
                }
            }
        },

        modify () {

        },

        reprintFiscalReceipt () {
            if (isDesktop) {
                try {
                    const { ipcRenderer, } = window.require("electron");

                    ipcRenderer.send("reprintFiscalReceipt", this.order.id);
                }
                catch {
                    // Silence is golden.
                }
            }
        },

        bindFixedMenuProductLocalizations (order) {
            order.products.forEach((product) => {
                if (this.restaurantProcessor.productIsFixedMenu(product.id)) {
                    for (const menu of product.requestedMenus) {
                        menu.products.forEach((fixedMenuProduct) => {
                            fixedMenuProduct.localizations = this.restaurantProcessor.getProductById(fixedMenuProduct.id).localizations;
                        })
                    }
                }
            });
        },

        bindCustomProductProductLocalizations (order) {
            order.products.forEach((product) => {
                if (this.restaurantProcessor.productIsCustomProduct(product.id)) {
                    for (const menu of product.requestedMenus) {
                        menu.products.forEach((customProductProduct) => {
                            customProductProduct.localizations = this.restaurantProcessor.getProductById(customProductProduct.id).localizations;
                        });
                    }
                }
            });
        },

        getOrderProductVariationsByVolumeIndex (orderProduct, volumeIndex) {
            return orderProduct.variations.filter(
                (variation) => variation.volumeIndex === volumeIndex
            ).map(
                (variation) => this.restaurantProcessor.getVariationById(variation.id)
            );
        },

        getOrderProductVariationsPriceByVolumeIndex (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).reduce(
                (total, variation) => total += this.restaurantProcessor.getVariationById(variation.id).price, 0
            );
        },

        getOrderProductVariationsTextByVolumeIndex (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).reduce(
                (text, variation) => text += ", " + this.restaurantProcessor.getVariationLocalizedName(variation.id).toLocaleLowerCase(), ""
            );
        },

        orderProductVolumeIndexHasVariations (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).length > 0;
        },

        getProductVariationsTotalPrice (orderProduct) {
            const productVariations = orderProduct.variations;
            let totalPrice = 0;
            totalPrice += productVariations.reduce((total, variation) => total + this.restaurantProcessor.getVariationById(variation.id).price, 0);

            return totalPrice;
        },

        getProductsNoVariations (orderProduct) {
            let total = 0;
            for (let i = 0; i < orderProduct.requestedVolume; i++) {
                if (this.orderProductVolumeIndexHasVariations(orderProduct, i)) {
                    total++;
                }
            }
            return orderProduct.requestedVolume - total;
        },

        getNoVariationProductTotalPrice (orderProduct) {
            return this.normalizePriceToDisplay(this.getProductsNoVariations(orderProduct) * orderProduct.unitPrice + this.getDiscountedPrice(orderProduct, this.getProductsNoVariations(orderProduct)))
        },

        getNoVariationProductBeforeDiscount (orderProduct) {
            return this.normalizePriceToDisplay(this.getProductsNoVariations(orderProduct) * orderProduct.unitPrice);
        },

        getVariationProductSinglePrice (orderProduct, i) {
            return this.normalizePriceToDisplay(orderProduct.unitPrice + this.getOrderProductVariationsPriceByVolumeIndex(orderProduct, i) + this.getDiscountedPrice(orderProduct, 1)) ;
        },

        getVariationProductSinglePriceBeforeDiscount (orderProduct, i) {
            return this.normalizePriceToDisplay(orderProduct.unitPrice + this.getOrderProductVariationsPriceByVolumeIndex(orderProduct, i));
        },

        getDiscountedPrice (orderProduct, volume) {
            let totalPrice = 0;

            if (orderProduct.discountStrategies) {
                if (this.onePlusOne(orderProduct)) {
                    totalPrice -= DiscountStrategies.onePlusOne.applyDiscount(
                        volume,
                        orderProduct.unitPrice
                    );
                }

                const fixedDiscount = this.fixedDiscount(orderProduct);
                if (fixedDiscount) {
                    totalPrice -= DiscountStrategies.fixed.applyDiscount(
                        volume,
                        orderProduct.unitPrice,
                        fixedDiscount,
                    );
                }

                const percentageDiscount = this.percentageDiscount(orderProduct);
                if (percentageDiscount) {
                    totalPrice -= DiscountStrategies.percentage.applyDiscount(
                        volume,
                        orderProduct.unitPrice,
                        percentageDiscount
                    );
                }
            }

            return totalPrice;
        },

        onePlusOne (orderProduct) {
            return orderProduct.discountStrategies?.includes(DiscountStrategies.onePlusOne.id);
        },

        fixedDiscount (orderProduct) {
            if (!orderProduct.discountStrategies) {
                return false;
            }
            const fixedDiscount = orderProduct.discountStrategies.find((s) => String(s).startsWith("-"));
            return fixedDiscount ? fixedDiscount.substring(1) : false;
        },

        percentageDiscount (orderProduct) {
            if (!orderProduct.discountStrategies) {
                return false;
            }
            const percentageDiscount = orderProduct.discountStrategies.find((s) => String(s).startsWith("%"));
            return percentageDiscount ? percentageDiscount.substring(1) : false;
        },
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurant () {
            return this.restaurantProcessor.restaurant;
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },
        // </restaurant>

        date () {
            return new Date(Number.parseInt(this.order.creationTimestamp));
        },

        paymentDateString () {
            const date = new Date(Number.parseInt(this.order.paymentTimestamp));

            if (Number.isNaN(date.getTime())) {
                return this.$t("generic.notApplicable");
            }

            return date.toLocaleString(this.$i18n.locale);
        },

        deliveryDateString () {
            const date = new Date(Number.parseInt(this.order.deliveryTimestamp));

            if (Number.isNaN(date.getTime())) {
                return this.$t("generic.notApplicable");
            }

            return date.toLocaleString(this.$i18n.locale);
        },
    },
    watch: {
        isExpanded (value) {
            if (value && this.expandableRef) {
                this.expandableRef.style.height = this.getElementInnerAbsoluteHeight(this.expandableRef) + "px";
            }
            else if (this.expandableRef) {
                this.expandableRef.style.height = "0px";
            }
        },
    },
    created () {
        if (!this.compactView) {
            this.isExpanded = true;
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.restaurant-order {
    &__box {
        position: relative;
        padding: 25px;
        background-color: rgb(254, 254, 254);
        border-radius: 6px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    }

    &__key {
        color: rgb(20, 20, 20);
        font-size: 15px;
        font-weight: 300;
    }

    &__value {
        color: rgb(20, 20, 20);
        font-size: 15px;
        font-weight: 500;
    }

    &__line-through {
        text-decoration: line-through;
        padding-right: 5px;
    }

    &__expanded {
        overflow: hidden;

        height: 0;
    }
    &--compact-view &__expanded {
        transition: height 300ms ease-out;
    }
}

.title {
    color: rgb(20, 20, 20);
    font-size: 16px;
    font-weight: 600;
}

.label {
    padding: 4px 6px;
    background-color: rgb(70, 70, 70);
    border-radius: 4px;
    color: rgb(254, 254, 254);
    font-size: 13px;
    font-weight: 600;
}

.order-status {
    &--in-preparation .label {
        background-color: rgb(242, 152, 7);
        background-image: linear-gradient(45deg, rgb(242, 152, 7), rgb(250, 166, 30));
    }

    &--delivered .label {
        background-color: rgb(14, 161, 32);
        background-image: linear-gradient(45deg, rgb(14, 161, 32), rgb(21, 153, 38));
    }
}

.product-name {
    font-size: 15px;
    font-weight: 500;
    color: rgb(20, 20, 20);
}

.product-price {
    font-size: 15px;
    font-weight: 500;
    color: rgb(20, 20, 20);

    &__discounted {
        margin-left: 3px;
        text-decoration: line-through;
    }
}

.fixed-menu-product-list {
    margin: 0;
    padding: 10px 20px;
}

.fixed-menu-product {
    font-size: 15px;
    font-weight: 500;
    color: rgb(20, 20, 20);

    & + & {
        margin-top: 4px;
    }
}

.product-status {
    padding: 4px 6px;
    background-color: rgb(70, 70, 70);
    border-radius: 4px;

    font-size: 13px;
    font-weight: 600;
    color: rgb(254, 254, 254);

    &--in-preparation {
        background-color: rgb(242, 152, 7);
        background-image: linear-gradient(45deg, rgb(242, 152, 7), rgb(250, 166, 30));
    }

    &--in-delivery {
        background-color: rgb(245, 191, 66);
        background-image: linear-gradient(45deg, rgb(245, 191, 66), rgb(230, 210, 76));
    }

    &--delivered {
        background-color: rgb(14, 161, 32);
        background-image: linear-gradient(45deg, rgb(14, 161, 32), rgb(21, 153, 38));
    }
}

.separator {
    width: 100%;
    height: 1px;
    margin: 15px 0;
    padding: 0;
    background-color: rgb(229, 229, 229);
}

.item-list {
    margin: 8px 0 0 18px;
    padding: 0;
    list-style-type: none;

    &__item {
        color: rgb(20, 20, 20);
        font-size: 15px;
        font-weight: 500;
    }
}
.item-list__item + .item-list__item {
    margin-top: 5px;
}

.arrow-button {
    cursor: pointer;

    margin: 0;
    padding: 10px 14px;

    &__icon {
        will-change: transform;
        transition: transform 300ms;

        width: 32px;
        height: auto;

        fill: rgb(70, 70, 70);
    }
}
.restaurant-order--expanded .arrow-button {
    &__icon {
        transform: rotate(180deg);
    }
}

.cashier-control-box {
    transition: 256ms opacity;

    margin: 0 0 10px 0;

    opacity: 0;

    :deep(.simple-button) {
        min-width: initial;
        padding: 5px 12px;

        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    }
    :deep(.simple-button .simple-button__text) {
        padding: 0;
        font-size: 11px;
    }
    :deep(.simple-button .simple-button__loading-spinner) {
        transform: translate(-50%, -50%) scale(0.14);
    }
    :deep(.simple-button + .simple-button) {
        margin-left: 6px;
    }
}

.restaurant-order:hover > .cashier-control-box {
    opacity: 1;
}

.product-tag {
    display: inline-block;
    margin-left: 5px;
    padding: 4px 6px;
    border-radius: 4px;
    color: white;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;

    &__new-arrival {
        background-color: #00CC66;
    }

    &__discount {
        background-color: #FF4500;
    }

    &__onePlusOne {
        background-color: $primary-brand-color;
    }
}
</style>
