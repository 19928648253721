<template>
    <div class="flex flex--100 flex--x-align-center home-navigation">
        <div class="flex flex--80 flex--y-align-center navigation-main-box">
            <div class="flex flex--40 flex--y-align-center home-navigation__box logo-box">
                <primary-logo/>
                <h1 class="brand-title">{{ $t("brandName") }}</h1>
            </div>
            <div class="flex flex--60 flex--x-align-end home-navigation__box">
                <nav class="menu menu--static">
                    <span class="menu__item" :class="getMenuItemModifiers(0)" @click="goto(`/`)">{{ $t("homepage.home") }}</span>
                    <!-- <span class="menu__item" :class="getMenuItemModifiers(1)">{{ $t("homepage.ourIdea") }}</span> -->
                    <span class="menu__item" :class="getMenuItemModifiers(2)" @click="goto(`/restaurant/list`)">{{ $t("homepage.orderNow") }}</span>
                    <router-link to="/login" class="menu__item">{{ $t("homepage.logIn") }}</router-link>
                </nav>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <Slide class="hamburger">
                    <div class="flex flex--100 flex--direction-colum">
                        <nav class="menu">
                            <span class="menu__item" :class="getMenuItemModifiers(0)" @click="goto(`/`)">{{ $t("homepage.home") }}</span>
                            <!-- <span class="menu__item" :class="getMenuItemModifiers(1)">{{ $t("homepage.ourIdea") }}</span> -->
                            <span class="menu__item" :class="getMenuItemModifiers(2)" @click="goto(`/restaurant/list`)">{{ $t("homepage.orderNow") }}</span>
                            <router-link to="/login" class="menu__item">{{ $t("homepage.logIn") }}</router-link>
                        </nav>
                    </div>
                </Slide>
            </div>
        </div>
    </div>
</template>

<script>
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { Slide } from "vue3-burger-menu";

export default {
    name: "HomeNavigation",
    components: {
        PrimaryLogo,
        Slide,
    },
    props: {
        selected: {
            type: Number,
            default: 0,
        }
    },
    methods: {
        getMenuItemModifiers (value) {
            return {
                "menu__item--selected": this.selected === value,
            }
        },

        goto (viewLocation) {
            if (viewLocation === window.location.pathname) {
                return;
            }
            this.$router.push({ path: viewLocation, });
        },
    }
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.home-navigation {
    background-color: rgb(0, 0, 0);

    &__box {
        padding: 18px 50px;
    }
}

.hamburger {
    display: none;

    :deep {
        .bm-burger-button {
            top: 10%;
            left: 50%;
            right: initial;
            bottom: initial;

            transform: translateX(-50%);
        }

        .bm-burger-bars {
            background-color: $primary-brand-color;
        }
    }
}

@media only screen and (max-width: 1322px) {
    .hamburger {
        display: initial;
    }

    .menu.menu--static {
        display: none;
    }

    .logo-box {
        justify-content: center;
        width: 100%;
    }

    .navigation-main-box {
        padding: 60px 0;
    }
}

.brand-title {
    margin: 10px 30px 10px 30px;
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    color: $primary-brand-color;
}

.menu {
    &__item {
        display: inline-flex;
        text-transform: uppercase;
        transition: font-weight 200ms, color 300ms;

        cursor: pointer;

        position: relative;

        margin: 10px;
        padding: 20px;

        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.07rem;
        text-decoration: none;
        color: rgb(255, 255, 255);
    }
    /*
    &__item::before {
        transition: width 300ms;

        content: "";

        position: absolute;
        left: 0;
        bottom: 10px;

        width: 0;
        height: 2px;

        background-color: $primary-brand-color;

        transform: skew(-45deg);
    }*/
    &__item:hover,
    &__item--selected {
        font-weight: bold;
        color: $primary-brand-color;
    }/*
    &__item:hover::before,
    &__item--selected::before {
        width: 100%;
    }*/
}

.hamburger {
    position: absolute;

    z-index: 10030434;
}

.primary-logo {
    width: 90%;
    max-width: 94px;
    fill: $primary-brand-color;
}
</style>
