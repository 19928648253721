<template>
    <div class="flex simple-textbox" :class="getModifiers()">
        <label class="flex flex--100 label">
            <div class="label__box">{{ placeholder }}</div>
            <input
                :type="inputType"
                class="flex flex--100 simple-textbox__text-input"
                :disabled="isDisabled"
                :readonly="isReadonly"
                :value="modelValue"
                @input="onInput"
                @focus="onFocus"
                @blur="onBlur"
                v-if="!isMultiline"
            >
            <textarea
                class="flex flex--100 simple-textbox__text-input simple-textbox__text-input--multiline"
                :disabled="isDisabled"
                :readonly="isReadonly"
                :value="modelValue"
                @input="onInput"
                @focus="onFocus"
                @blur="onBlur"
                v-else
            />
            <span v-if="placeholder.length > 0" class="placeholder" ref="placeholder">{{ placeholder }}</span>
        </label>
        <div class="flex flex--100 flex--direction-column error-list" v-if="!isValid">
            <span v-for="error in errors" :key="error.id" class="error-list__item">{{ error.text }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "SimpleTextbox",
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isPassword: {
            type: Boolean,
            default: false,
        },
        isMultiline: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: [ "update:modelValue", "input" ],
    data () {
        return {
            isFocused: false,
            errors: [],
        };
    },
    methods: {
        getModifiers () {
            return {
                "simple-textbox--error": !this.isValid,
                "simple-textbox--empty": this.modelValue && this.modelValue.length === 0,
                "simple-textbox--not-empty": this.modelValue && this.modelValue.length > 0,
                "simple-textbox--focused": this.isFocused,
            };
        },

        onFocus () {
            this.isFocused = true;
        },

        onBlur () {
            this.isFocused = false;
        },

        onInput (event) {
            this.$emit("update:modelValue", event.target.value);
            this.$emit("input", event.target.value);
        },

        addError (error) {
            if (!this.hasError(error)) {
                this.errors.push(error);
            }
        },

        getErrorIndexById (id) {
            for (let i = 0; i < this.errors.length; ++i) {
                const error = this.errors[i];

                if (error.id === id) {
                    return i;
                }
            }

            return -1;
        },

        hasError (error) {
            return this.getErrorIndexById(error.id) !== -1;
        },

        removeError (error) {
            const i = this.getErrorIndexById(error.id);

            if (i !== -1) {
                this.errors.splice(i, 1);
            }
        },

        clearErrors () {
            this.errors = [];
        },

        validate (error, condition) {
            if (!condition) {
                this.addError(error);
            }
            else {
                this.removeError(error);
            }

            return condition;
        },
    },
    computed: {
        inputType () {
            return this.isPassword ? "password" : "text";
        },

        isValid () {
            return this.errors.length === 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.label {
    position: relative;

    &__box {
        transition: background-color 200ms, width 300ms;
        content: "";
        position: absolute;
        left: 18px;
        top: -4px;

        width: auto;
        height: 10px;
        padding: 0 8px;

        background-color: transparent;

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: transparent;

        z-index: 10;
    }
}

.placeholder {
    transition: left 300ms, top 300ms;
    position: absolute;
    left: 22px;
    top: 50%;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.02rem;
    color: rgb(120, 120, 120);

    transform: translateY(-50%);

    z-index: 11;
}

.simple-textbox {
    $background-color: rgb(253, 253, 253);

    &__text-input {
        transition: box-shadow 190ms, border-color 190ms;
        appearance: none;

        margin: 0;
        padding: 14px 20px;

        background-color: $background-color;
        border: 1px solid rgb(220, 220, 220);
        border-radius: 12px;

        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: rgb(35, 32, 33);
    }
    &__text-input:focus {
        box-shadow: 0 0 0 2px rgb(90, 90, 90);
        border-color: rgb(90, 90, 90);
    }
    &--focused .placeholder,
    &--not-empty .placeholder {
        left: 26px;
        top: 0;
    }
    &__text-input::placeholder {
        color: rgb(157, 157, 157);
    }
    &__text-input--multiline {
        resize: none;
    }
    &--error &__text-input {
        border-color: rgb(227, 130, 130);
        box-shadow: 0 0 0 2px rgb(227, 130, 130);
    }
    &--error &__text-input::placeholder {
        color: rgb(227, 50, 50);
    }

    &--focused .label__box,
    &--not-empty .label__box {
        background-color: $background-color;
    }
}

.error-list {
    margin: 14px 20px 0 20px;
    padding: 0;

    &__item {
        font-size: 13px;
        font-weight: 400;
        color: rgb(227, 50, 50);
    }
}
.error-list__item + .error-list__item {
    margin-top: 5px;
}
</style>
