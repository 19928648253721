<template>
    <a href="https://www.iubenda.com/privacy-policy/50800278" target="_blank" class="flex privacy-policy">
        Privacy Policy
    </a>
</template>

<script>
export default {
    name: "PrivacyPolicy",
};
</script>

<style lang="scss" scoped>
.privacy-policy {
    margin: 0;
    padding: 0;

    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    text-decoration: inherit;
}
</style>
