<template>
    <div class="stripe-we-chat-pay-payment">
        <form class="payment-form" v-show="hasPaymentIntent">
            <div class="flex flex--100 flex--x-align-center" v-if="error">
                <span class="error-text">{{ error.message }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <we-chat-pay-icon/>
                <img class="qr-code-image" alt="" :src="qrBase64"/>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <simple-button :text="$t('payment.paidConfirmation')" class="pay-button" :is-loading="isWaitingServerResponseForOrderConfirmation" @click="tryConfirmPayment"/>
            </div>
        </form>
    </div>
</template>

<script>
import WeChatPayIcon from "@/components/icons/WeChatPayIcon";
import { OrderDeliveryLocation, OrderOrigin, } from "@/orders/RestaurantOrder";
import { BasketProcessor, RestaurantProcessor, } from "@/utilities";
import { loadStripe, } from "@stripe/stripe-js";
import { server, } from "@/server";
import SimpleButton from "@/components/inputs/SimpleButton";
import { isKiosk, } from "@/main";

export default {
    name: "StripeWeChatPayPayment",
    components: {
        WeChatPayIcon,
        SimpleButton,
    },
    props: {
        restaurantPresentation: {
            type: Object,
        },
        basket: {
            type: Object,
        },
    },
    emits: [
        "success",
        "load",
    ],
    data () {
        return {
            error: undefined,
            hasPaymentIntent: false,
            publishableKey: undefined,
            clientSecret: undefined,
            paymentFormBox: undefined,
            stripe: undefined,
            isWaitingServerResponseForOrderConfirmation: false,
            qrBase64: undefined,
            paymentIntent: undefined,
        }
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, })
        },

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },

        basketSelectedProducts () {
            return this.basketProcessor.selectedProducts;
        },

        basketSelectedFixedMenus() {
            return this.basketProcessor.selectedFixedMenus;
        },
        // </basket>
    },
    methods: {
        async tryConfirmPayment () {
            if (!this.paymentIntent) {
                return;
            }
            // For backend to get restaurant id,
            const products = [];
            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });
            console.log(this.paymentIntent);

            this.error = undefined;
            this.isWaitingServerResponseForOrderConfirmation = true;

            const paymentIntentId = this.paymentIntent.id;
            const confirmation = await server.confirmStripePaymentIntent({ paymentIntentId, products, });
            const confirmedOrder = confirmation.order;

            if (confirmedOrder) {
                this.hasPaymentIntent = false;
                this.paymentIntent = undefined;

                this.$emit("success", confirmedOrder);
            }
            else {
                this.error = {
                    message: this.$t("payment.notPaid"),
                };
            }

            this.isWaitingServerResponseForOrderConfirmation = false;
        },

        async createPaymentIntent () {
            this.hasPaymentIntent = false;

            const products = [];
            const fixedMenus = [];
            let deliveryLocation = this.basket.isTakeAway ? OrderDeliveryLocation.TAKE_AWAY : OrderDeliveryLocation.RESTAURANT;

            if (this.basket?.deliveryAddress?.streetName) {
                deliveryLocation = OrderDeliveryLocation.ADDRESS;
            }

            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            this.basketSelectedFixedMenus.forEach((fixedMenu) => {
                this.basket.selectedProducts[fixedMenu.id].selectedProducts.forEach((menu) => {
                    fixedMenus.push([
                        fixedMenu.id, ...menu.map((product) => Number.parseInt(Object.keys(product)[0])),
                    ]);
                });
            });

            const { publicKey, clientSecret } = await server.createStripePaymentIntent({
                sceneId: this.sceneId,
                origin: isKiosk ? OrderOrigin.KIOSK : OrderOrigin.WEBSITE,
                deliveryLocation,
                products,
                fixedMenus,
                requestedDeliveryTimestamp: this.basket.requestedDeliveryTimestamp,
                requestedPersons: this.basket.requestedPersons,
                discounts: this.basket.discounts,
                productsVariations: this.basket.selectedProductsVariations,
                productsMessages: this.basket.selectedProductsMessages,
                plainBasket: this.basket,
                intentPaymentMethod: "wechat_pay",
                deliveryAddress: this.basket.deliveryAddress,
                deliveryReceiver: this.basket.deliveryReceiver,
                message: this.basket.message,
            });

            this.publishableKey = publicKey;
            this.clientSecret = clientSecret;
            this.stripe = await loadStripe(publicKey);

            const { paymentIntent, } = await this.stripe.confirmWechatPayPayment(clientSecret, {
                payment_method_options: {
                    wechat_pay: {
                        client: "web",
                    },
                },
            }, {
                handleActions: false,
            });

            if (!paymentIntent) {
                return;
            }

            this.paymentIntent = paymentIntent;
            this.qrBase64 = paymentIntent.next_action.wechat_pay_display_qr_code.image_data_url;
            this.hasPaymentIntent = true;

            this.$emit("load");
        }
    },
    mounted () {
        this.createPaymentIntent();
    },
};
</script>

<style lang="scss" scoped>
.stripe-we-chat-pay-payment {
    width: 90%;
    max-width: 424px;

    padding: 32px 52px;

    background-color: rgb(240, 240, 240);
    border-radius: 12px;
}

.payment-form {
    margin: 0;
}

.error-text {
    margin: 0 0 28px 0;

    font-size: 18px;
    font-weight: 600;
    color: #df1b41;
}

.pay-button {
    margin: 28px 0 0 0;

    width: 100%;
}

.qr-code-image {
    width: 224px;
    height: auto;
}

.wechat-pay-icon {
    width: 224px;

    margin: 0 0 22px 0;

    :deep(*) {
        fill: rgb(0, 0, 0);
    }
}
</style>
